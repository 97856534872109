import ContainerMain from "pages/common/ContainerMain"

import image1 from "images/Company/About/mission.png"

import "./Mission.scss"
import { useFormatter } from "helpers/i18n"

export default function Mission() {
  const { __ } = useFormatter()
  return (
    <>
      <div className="njtayjxbnk">
        <ContainerMain alignType="left">
          <div className="wslxgltezo">
            <div className="cpgxvbdfde">
              <div className="ovcnynaaht">
                <img src={image1} alt="Our Mission" className="cfxptyiduo" />
              </div>
              <div className="clrpfddfkm-wrapper">
                <div className="clrpfddfkm">
                  <div className="aeqaadvlht">
                    {__({
                      defaultMessage: "Vision",
                    })}
                  </div>
                  <div className="tfgqdwqcbl">
                    {__({
                      defaultMessage:
                        "Pioneer in building the number one SEA's e-commerce enabling ecosystem",
                    })}
                  </div>
                  <div className="aeqaadvlht">
                    {__({
                      defaultMessage: "Mission",
                    })}
                  </div>
                  <div className="tfgqdwqcbl">
                    {__({
                      defaultMessage:
                        "Empower E-commerce for brands and businesses",
                    })}
                  </div>
                  <div className="aeqaadvlht">
                    {__({
                      defaultMessage: "Core Values",
                    })}
                  </div>
                  <ul className="qhpgcqijqp">
                    <li className="mutpmyotjf">
                      {__({
                        defaultMessage: "Client first",
                      })}
                    </li>
                    <li className="mutpmyotjf">
                      {__({
                        defaultMessage: "Commitment",
                      })}
                    </li>
                    <li className="mutpmyotjf">
                      {__({
                        defaultMessage: "Passion",
                      })}
                    </li>
                    <li className="mutpmyotjf">
                      {__({
                        defaultMessage: "Embrace change",
                      })}
                    </li>
                    <li className="mutpmyotjf">
                      {__({
                        defaultMessage: "Teamwork",
                      })}
                    </li>
                    <li className="mutpmyotjf">
                      {__({
                        defaultMessage: "Integrity",
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </>
  )
}
